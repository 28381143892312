.header {
  position: fixed;
  /* background-color: green; */
  /* background: url("https://doc-08-2c-docs.googleusercontent.com/docs/securesc/68c90smiglihng9534mvqmq1946dmis5/fo0picsp1nhiucmc0l25s29respgpr4j/1631524275000/03522360960922298374/03522360960922298374/1Sx0jhdpEpnNIydS4rnN4kHSJtU1EyWka?e=view&authuser=0&nonce=gcrocepgbb17m&user=03522360960922298374&hash=tfhgbs86ka6divo3llbvp93mg4csvb38")
    no-repeat center/ cover !important; */
  left: 0;
  right: 0;
  top: 0;
  color: white;
  /* height: 10vh; */
  z-index: 100;
  background: linear-gradient(to bottom, #0f0c29, #302b63, #24243e);
  

}
.header ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  height: 60px;

  text-transform: uppercase !important;
}
.header ul li {
  flex: 1;
  margin: 0;
  padding: 0;
  text-align: right;
  align-items: center;
  /* justify-items: center; */
  /* border: 1px solid grey; */
  padding-top: 15px;
}
