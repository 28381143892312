.headerbgup {
  position: fixed;
  background-color: rgb(28, 26, 26);
  padding: 10px;
  font-family: "DM Sans", sans-serif !important;
  color: white;
  left: 0;
  right: 0;
  top: 0;
}
.secondTab label {
  text-align: right;
  margin-left: 10px;
}
.secondTab {
  display: flex;
  align-items: center;
  justify-content: right;
}

