.Main {
  height: 90vh;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  text-align: center;
  color: white;
  overflow-x: hidden;
}
.Main h3 {
  font-weight: 900;
  font-size: 45px;
  /* font-size: 72px; */
  background: -webkit-linear-gradient(rgb(238, 238, 238), #00cdcc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.Main button {
  border: none;
  margin: 10px;
  padding: 10px;
  width: 250px;
  margin-top: 30px;
  color: black;
}
.floatDescription {
  position: absolute;
  background-color: #00cdcc;
  color: white;
  padding: 20px;
  left: 0;
  top: 0;
  width: 70%;
  margin: 15%;
  margin-top: 80vh;
}
#video {
  position: absolute;
  left: 0;
  z-index: -1;
  top: 0;
  right: 0;

  object-fit: cover;
  width: 100%;
}

@media only screen and (orientation: portrait) {
  .floatDescription {
    display: none;
  }
  .Main {
    background: url(https://images.unsplash.com/photo-1536148935331-408321065b18?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDIwfHx8ZW58MHx8fHw%3D&w=1000&q=80);
    /* background: url("../../Images/bg.png") no-repeat; */

    background-size: cover;
    height: 80vh;
  }
  .Main h3 {
    font-weight: 900;
    font-size: 25px;

    /* background: -webkit-linear-gradient(rgb(238, 238, 238), rgb(97, 191, 253)); */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  #video {
    display: none;
  }
}
