@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");
.page {
  background: rgb(238, 238, 238);
}

.maindas {
  height: 30vh;
  /* background-color: wheat; */
}

.pagewidth {
  width: 70%;
  margin: auto;
}

.pagewidth ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
.pagewidth ul li {
  padding: 8px;
  padding-left: 3px;
  width: 50%;
}
.pagewidth ul li div {
  border: 1px solid rgb(252, 218, 252);
  margin: 1%;
  padding: 10px;
}
.cols {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* -------------------------------------------------------------------------------------------------- */

.timeline {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  /* background-color: green; */
}

.li {
  transition: all 200ms ease-in;
  /* background-color: green; */
  width: 100%;
}

.timestamp {
  margin-bottom: 20px;
  padding: 0px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 100;
}

.status {
  padding: 0px 40px;
  display: flex;
  justify-content: center;
  border-top: 2px solid #d6dce0;
  position: relative;
  transition: all 200ms ease-in;
}
.status h4 {
  font-weight: 600;
  margin-top: 10px;
  font-size: 13px;
}
.status:before {
  content: "";
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 25px;
  border: 1px solid #ddd;
  position: absolute;
  top: -15px;
  left: 42%;
  transition: all 200ms ease-in;
}

.li.complete .status {
  border-top: 2px solid #66dc71;
}
.li.complete .status:before {
  background-color: #66dc71;
  border: none;
  transition: all 200ms ease-in;
}
.li.complete .status h4 {
  color: #66dc71;
}


/* ---------------------------------------------------------------------------- */
.sponsor__container {
  /* grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  justify-items: center;
  row-gap: 3.5rem; */
  width: 50%;
  display: flex;
  margin: auto;
}
.sponsor__content {
  margin: 20px;
}

.sponsor__content:hover .sponsor__img {
  filter: invert(0.5);
}

.sponsor__img {
  width: 90px;
  filter: invert(0.7);
  transition: var(--img-transition);
}

@media (min-device-width: 320px) and (max-device-width: 700px) {
  .timeline {
    list-style-type: none;
    display: block;
  }

  .li {
    transition: all 200ms ease-in;
    display: flex;
    width: inherit;
  }

  .timestamp {
    width: 100px;
  }

  .status:before {
    left: -8%;
    top: 30%;
    transition: all 200ms ease-in;
  }
}
/* html,
body {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  font-family: "Titillium Web", sans serif;
  color: #758d96;
}

button {
  position: absolute;
  width: 100px;
  min-width: 100px;
  padding: 20px;
  margin: 20px;
  font-family: "Titillium Web", sans serif;
  border: none;
  color: white;
  font-size: 16px;
  text-align: center;
}

#toggleButton {
  position: absolute;
  left: 50px;
  top: 20px;
  background-color: #75c7f6;
} */
