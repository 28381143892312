.vip .row .cloo .divdd {
  border: 1px solid blueviolet;
  margin: 5px;
  border-radius: 8px;
  position: relative !important;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
}
.cloo {
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
  position: relative !important;
}
.viptab {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: blueviolet;
  color: white;
}
.vip button {
  position: absolute;
  width: 72%;
  margin-top: -20px;
  margin-left: 24%;
  border: none;
  padding: 8px;
  border-radius: 10px;
  background-color: rgb(218, 186, 249);
  /* display: none; */
}
.approvedbtn {
  position: relative !important;
  margin: 0 !important;
  padding: 0 !important;
  padding: 10px !important;
  width: 240px !important;
  background-color: red !important;
}
