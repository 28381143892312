@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Marcellus&display=swap");
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300);
@import url("https://fonts.googleapis.com/css2?family=Marcellus&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap");

body {
  /* font-family: "DM Sans", sans-serif; */
  font-family: "Marcellus", serif;
  font-weight: 400;
  font-style: normal;
  /* font: 16px/26px "Raleway", sans-serif; */
  font-optical-sizing: auto;
  font-style: normal;
  background: rgb(238, 238, 238);
  font-size: 13px;
  /* font-size: 1em !important; */

  /* background-color: aquamarine; */
}

.page {
  margin-top: 2vh;
  /* height: 100vh; */
}
a {
  text-decoration: none;
}

@media only screen and (orientation: portrait) {
  .body {
    font-size: 12px !important;
  }
}

@media only screen and (orientation: portrait) {
  .mainlogin {
    padding: 10px;
    border-radius: 10px;
    width: 94% !important;
  }
}
