.tsk img {
  border-radius: 50%;
  height: 80px;
  margin-bottom: 20px;
}

.tsk .row .col:nth-child(1) {
  display: block;
  /* background-color: green; */
}
.tsktab {
  /* background-color:  ; */
  padding: 6px;
}
.sidecol {
  display: flex;
}
.sidecol .tsktab {
  width: 50%;
}
.sidecol ul {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: white;
  padding: 10px;
  height: 20vh;
  border-radius: 8px;
}
.sidecol .tsktab {
  padding: 10px;
}
.sidecol .tsktab .row .col {
  font-weight: 800;
  color: rgb(85, 58, 4);
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.sidecol .tsktab label {
  font-size: 18px;
  color: #ffb02c;
  font-weight: 600;
}
.tsktab h4 {
  color: #af7005;
  font-weight: 900;
  text-align: center;
}
.tsktab p {
  color: rgb(50, 49, 49);
  font-weight: 400;
  text-align: center;
}
.sidecol .tsktab button {
  background-color: brown;
  color: white;
  width: 100%;
}
.bottomfixed {
  position: fixed;
  /* background-color: #c3f9da; */
  /* height: 7vh; */
  bottom: 6px;
  width: 100%;
  left: 0;
  font-weight: 600;
  padding: 8px;
  /* height: 8vh; */
}
.bottomfixed .container {
  background-color: #ffecc6;
  padding: 7px;
  border-radius: 10px;
}

.bottomfixed label {
  font-size: 18px;
  font-weight: bold;
}
.bottomfixed button {
  height: 100%;
  width: 100%;
  background-color: white;
}
.fixedtop {
  position: sticky;
  top: 0;
}
hr {
  z-index: -1 !important;
}
.topbar {
  background: rgb(249, 223, 223);
  /* border: 1px solid rgb(249, 223, 223); */
  /* background-color: #; */
  z-index: 100 !important;
  /* background-color: green; */
}
.taskbar {
  z-index: 10 !important;
  margin-bottom: 10vh;
}
.menu ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: 2px solid white;
  border-bottom: 1px solid rgb(249, 223, 223);
}
.menu ul li {
  flex: 1;
  margin: 0;
  padding: 0;
  text-align: center;
  font-weight: 500;
  padding: 10px;
}
.menu .active {
  background-color: white;
  /* border-right: 2px solid white; */
}
.menu .normal {
  border-left: 2px solid white;
  border-right: 2px solid white;
}

@media only screen and (orientation: portrait) {
  .sidecol ul {
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: white;
    padding: 10px;
    height: auto;
    border-radius: 8px;
  }
  .taskbar {
    z-index: 10 !important;
    margin-bottom: 20vh;
  }
}

.text-center {
  text-align: center;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.btn-link {
  text-decoration: none;
  color: #007bff;
}

.btn-link:hover {
  text-decoration: underline;
}
