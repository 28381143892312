.cardul {
  display: flex;
  padding: 0;
  margin: 0;
  /* background-color: green; */
  list-style: none;
  /* height: 25vh; */
  background-color: white;
  padding: 10px;
  border-radius: 8px;
}
.cardul li:nth-child(1) {
  width: 45%;
  margin: 0;
}
.cardul span {
  font-size: 12px;
  list-style: grey;
}
.cardul label {
  font-size: 15px;
  color: #ea9708 !important;
  font-weight: bold;
}
.cardul label:nth-child(2) {
  font-size: 15px;
  color: #ffb02c !important;
  font-weight: bold;
}
