.logopage {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: green; */

  /* filter: blur(10px);  */
  /* z-index: -1;  */
}
.logopage::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../img/wallpaper.webp");
  background-size: cover; /* This will make sure the image covers the whole element */
  background-position: center; /* This centers the image */
  background-repeat: no-repeat;
  filter: blur(7px); /* Adjust the blur amount as needed */
  z-index: -1; /* Ensure the background stays behind the content */
}
.mainlogin {
  /* height: 100vh; */
  background-color: white;
  padding: 25px;
  border-radius: 10px;
  width: 22% !important;
}

@media only screen and (orientation: portrait) {
  .mainlogin {
    padding: 20px;
    border-radius: 10px;
    width: 85% !important;
    /* background-color: purple; */
  }
}
